export const useFormatStringToBraces = (str: string) => {
  if (!str) return "";

  return useFormatString(str, {
    replaceAllStartEnd: [
      [/\[/g, "{"],
      [/\]/g, "}"],
    ],
  });
};
