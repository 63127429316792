export const useFormatStringToBrackets = (str: string) => {
  if (!str) return "";

  return useFormatString(str, {
    replaceAllStartEnd: [
      [/{/g, "["],
      [/}/g, "]"],
    ],
  });
};
